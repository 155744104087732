@use '../shared' as *;

@mixin general-container {
  display: flex;
  color: $color-white;
  flex-direction: column;
  margin: 12px 0;
  padding: 0;
  position: relative;
  border: 1px solid transparent;

  &-hero {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background: transparent;

    @media (min-width: $break-md) {
      display: grid;
      grid-gap: 0;
      grid-template-columns: 60% auto;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-expanded {
    @extend .general-container;
    background: $color-gray-dark;

    &:hover {
      background: $color-gray-dark;
    }

    // height: 100vh;

    div > h1 {
      font-size: $type-huge;
    }

  
  }

  input,
  textarea {
    padding: 12px !important;
    background: $color-gray-darkest;
    color: $color-white-default;
    font-family: $font-default !important;
    font-size: $type-sm !important;
    outline: none;
    border: none;
    width: 100%;
    box-sizing: border-box;
    height: 36px;
  }

  .button-small {
    background: transparent;
    font-size: $type-xxs;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-family: $font-default;
    font-weight: $regular;
    color: $color-white-default;
    background: $color-brand-note;
    margin: 12px 0;
    padding: 6px;
    text-transform: uppercase;

    img {
      height: 19px;
      vertical-align: middle;
      filter: brightness(100);
    }

    &:hover {
      background: $color-brand-slight;
    }
  }
}

@mixin general-compact {
  padding: 24px;
  margin: 12px 12px 24px 12px;
  background: $color-gray-medium;
}

@mixin general-data {
  padding: 12px;
  margin: 0;
  background: $color-white-element;
  order: 1;

  @media (min-width: $break-md) {
    padding: 24px;
  }

  h1 {
    font-size: $type-default;
    text-transform: uppercase;
    margin: 0 0 12px 0;
  }

  @media (min-width: $break-xs) {
    order: 0;
  }

  &-mini {
    @extend .general-data;
    min-height: auto;
  }
  &-activity {
    @extend .general-data;
    min-height: 108px;
    background: transparent;
    padding: 0;
  }

  &-hero {
    margin: 0;
    background: $color-white-element;
    order: 1;
    min-height: auto;

    @media (min-width: $break-md) {
      align-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: 0 24px;
      min-height: 240px;
    }
  }

  h1 {
    span {
      color: $color-white-half;
    }
  }
  h2 {
    span {
      color: $color-white-half;
    }
    small {
      font-size: $type-tiny;
      color: $color-white-half;
      padding: 0;
      margin: 0;
    }

    font-size: $type-h2;
    padding: 12px 0 0 0;
    margin: 0;
    font-weight: $default;
  }

  #logo {
    transform-origin: center;
  }

  &:hover {
    #logo {
      // transition: $transition-transform;
      // transform: scale(1.6) translateX(0);
      // transform: scale(1.6) rotate(-12deg);
      // transform-origin: center;
    }

    img {
      // transition: $transition-transform;
      // transform: scale(1.2);
    }

    #effect {
      // transition: $transition-transform;
      // transform: translateX(6px);
    }
  }

  &-meta {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 36% auto;
    flex-direction: column;
    align-items: center;
    margin: 12px 0;
  }

  &-list {
    margin: 0;

    @extend .general-data;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a {
      width: 100%;
    }

    .list-module {
      margin: 0.5em 0 0 0;
    }
  }

  &-tags {
    display: flex;
    flex-direction: column;
    margin: 12px 0 0 0;
  }

  &-duo {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: flex-end;
    margin: 12px 0;
  }

  &-ai {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0;
  }

  &-tri {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(3, auto);
    flex-direction: column;
    align-items: flex-end;
    margin: 12px 0;
    order: 0;

    @media (min-width: $break-xs) {
      order: 1;
    }
  }

  &-split {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 36% auto;
    flex-direction: column;
    align-items: start;
    margin: 12px 0;

    h2 {
      span {
        color: $color-white-half;
      }

      font-size: $type-h2;
      padding: 6px 0 0 0;
      margin: 0;
      font-weight: $default;
    }

    h3 {
      font-size: $type-h3;
      padding: 6px 0 0 0;
      margin: 0;
      font-weight: $default;
    }
  }
}

.general-container {
  @include general-container;
}

.general-container-loading {
  @include general-container;
  background: $color-pink-slight;
}

.general-container-dark {
  @include general-container;
}

.general-data {
  @include general-data;
}

.general-compact {
  @include general-compact;
}

.good {
  position: relative;
  overflow: hidden;
  background: radial-gradient(at top left, $color-mid-light 0%, $color-mid-note 72%);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(at top left, $color-mid-slight 0%, $color-mid-note 72%);
    opacity: 1;
    animation: radialGradientGood 4s infinite alternate;
    z-index: 1;
  }
}

@keyframes radialGradientGood {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.48;
  }
  100% {
    opacity: 1;
  }
}

.neutral {
  background: radial-gradient(at top left, $color-orange-light 0%, $color-gray-darkest 72%) !important;
}
.bad {
  background: radial-gradient(at top left, $color-red-light 0%, $color-gray-darkest 72%) !important;
}

.general-data-meter {
  @include general-data;
  padding: 24px;
  min-height: 240px;

  img {
    transform: none !important;
  }
}
.general-data-transparent {
  @include general-data;
  background: transparent;
  padding: 0;
}

.promotion-container {
  @include general-container;
  z-index: $z-module;
  transition: $transition-all;
  border: 6px solid transparent;

  &:hover {
    @media (min-width: $break-md) {
      background: $color-gray-darkest;
      border: 6px solid $color-brand-default;
      border-radius: 0;
      transform: rotate(-3deg) scale(1.1);
      transition: $transition-all;
    }
  }
  &-inert {
    @include general-container;
    padding: 6px 0;

    button {
      background: transparent;
      font-size: $type-default;
      outline: none;
      border: none;
      font-family: $font-default;
      cursor: pointer;
      text-decoration: none;
      font-weight: $regular;
      color: $color-brand;
      text-transform: uppercase;
      padding: 6px 12px;
    }
  }
}

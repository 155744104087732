@use '../shared' as *;

.recharts {
  &-wrapper {
    // max-height: 84px !important;
  }

  &-responsive-container {
    padding: 0;
  }

  &-tooltip-wrapper {
    z-index: 3 !important;
    box-shadow: -3px -3px 6px $color-gray-darkest;
  }
}

.trendline-percentage {
  position: absolute;
  top: 12px;
  left: auto;
  right: -12px;
  font-size: $type-md;
  font-weight: $regular;
  color: $color-white-default;
  padding: 3px 24px;
  border-radius: $radius-slight;
  background: $color-gray-light;
  z-index: $z-base;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-none {
    @extend .trendline-percentage;
    color: $color-white-default;
    background: $color-gray-light;
  }
  &-up {
    @extend .trendline-percentage;
    color: $color-mid;
    background: $color-mid-slight;
  }

  &-down {
    @extend .trendline-percentage;
    color: $color-pink;
    background: $color-pink-slight;
  }

  &:after {
    content: 'CHANGE';
    font-size: $type-note;
    margin: 3px 0;
    color: $color-white-medium;
  }
}

.chart-area {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  margin: 0 24px 12px 24px;
  z-index: $z-base;
  
  &-compact {
    @extend .chart-area;
  }

  &-placeholder {
    font-size: $type-tiny;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $default;
    color: $color-white-default;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    background: $color-white-element;
    margin: 0 0 12px 0;
    background: repeating-linear-gradient(0deg, $color-gray-light, $color-gray-light 1.5px, transparent 1.5px, transparent 3px);
    box-shadow: 0 0 36px inset $color-gray-light;
    border-radius: $radius-default;
    padding: 24px !important;
    min-height: 84px;
  }

  &-responsive {
  
    
    box-sizing: content-box;
    align-items: flex-start;
    box-sizing: content-box;
    background: $color-white-element;
    @media (min-width: $break-md) {
      margin: 0;
      // @media (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2),
      // (min-width: 1280px) and (min-resolution: 192dpi) {
      background: repeating-linear-gradient(
        0deg,
        $color-gray-light,
        $color-gray-light 1.5px,
        transparent 1.5px,
        transparent 3px
      );
      box-shadow: 0 0 36px inset $color-gray-light;
    }
    border-radius: $radius-default;
    padding: 24px;
    min-height: 84px;
    align-content: flex-end;

    &-compact {
      @extend .chart-area-responsive;
      min-height: 36px;
    padding:  24px;

      }


  }

  .chart-area-price {
    margin-bottom: 1em;
  }

  .custom-tooltip {
    background-color: $color-gray-darkest;
    color: $color-white;
    border-radius: $radius-default;
    box-shadow: 0 2px 4px $color-gray-light;
    font-size: $type-default;

    .color-box {
      display: inline-block;
      width: 9px;
      height: 9px;
      margin-right: 6px;
    }

    &.micro-tooltip {
      padding: 3px 6px;
      margin-top: -18%;
      font-size: 0.6rem;
    }

    &.normal-tooltip {
      // margin-top: -25px;
      padding: 6px 12px;
    }
  }

}

@use '../shared' as *;
@use '../components/general.scss' as *;

.chatter {
  min-height: 400px;
  background: radial-gradient(at top left, $color-blue-half 0%, $color-blue 72%) !important;

  &-graph {
    width: 60px;
    padding: 0;
    display: inline-flex;
    justify-content: space-between;
    margin: 0;
    align-items: center;

    i {
      font-size: $type-note;
      color: $color-white-default;
      line-height: $type-default;
      align-self: center;
      vertical-align: middle;
    }
  }

  &-data {
    @include general-data;
    padding: 36px 24px;
    min-width: 200px;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: flex-end;
    margin: 12px 0;

    h1 {
      font-size: $type-huge;
    }
  }

  $durationSlow: 48s;
  $duration: 24s;
  $durationFast: 12s;

  &-ticker {
    display: inline-block;
    white-space: nowrap;
    animation: ticker $duration linear infinite;

    &-item {
      padding: 0 24px;
      font-size: $type-default;
      color: $color-white;
      margin: 0;
      display: inline-flex;
      align-items: center;

      img {
        height: 16px;
        margin: 0 6px 0 0;
        align-self: center;
      }

      h1 {
        font-size: $type-default;
        padding: 0;
        margin: 0;
        transition: none;
        font-weight: $regular;
        color: $color-white-default;

        span {
          font-weight: $regular;
          margin: 0 0 0 6px;
          font-size: $type-default;
          color: $color-brand;
          text-transform: uppercase;
          padding: 1px 6px;
          background: $color-brand-slight;
          border-radius: $radius-slight;
        }
      }
    }

    &-wrap {
      position: relative;
      width: 100%;
      overflow: hidden;
      height: 36px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background-color: transparent;
      // background-size: 6px 6px;
      // background-image: radial-gradient(circle, $color-brand-slight 1px, transparent 1px);

      @media (min-width: $break-md) {
        // @media (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2),
        // (min-width: 1280px) and (min-resolution: 192dpi) {
        background: repeating-linear-gradient(
          0deg,
          $color-brand-note,
          $color-brand-note 1.5px,
          transparent 1.5px,
          transparent 3px
        );
        box-shadow: 0 0 24px inset $color-gray-slight;
      }

      label {
        font-size: $type-tiny;
        font-weight: $bold;
        color: $color-white;
        // background: $color-gray-darker;
        // background: linear-gradient(to right, $color-gray-darkest 48%, transparent 100%);
        display: flex;
        // position: absolute;
        // align-items: center;
        // height: 72%;
        margin: 3px 0;
        // padding: 0 48px 0 6px;
        z-index: $z-default;
      }
    }

    &-reverse {
      display: inline-block;
      white-space: nowrap;
      animation: tickerReverse $durationFast linear infinite;

      &-wrap {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 36px;
        background-color: transparent;
        background-size: 5px 5px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
      }

      &-item {
        padding: 0 24px;
        font-size: $type-tiny;
        margin: 0;
        display: inline-flex;
        align-items: center;

        h1 {
          font-size: $type-tiny;
          padding: 0;
          margin: 0;
          text-transform: none;
          transition: none;
          font-weight: $regular;
          color: $color-white;

          b {
            padding: 1px 6px;
            margin: 0 0 0 6px;
            background: $color-brand-light;
            border-radius: $radius-slight;
            font-size: $type-tiny;
            color: $color-white;
            text-transform: uppercase;
            font-weight: $regular;
          }
        }
      }
    }
  }
}

.scanner {
  &-status {
    margin: 0 0 12px 0;

    &-container {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;

   
    }

    b {
      font-weight: $regular;
      background: $color-green-slight;
      color: $color-green;
      padding: 1px 6px;
      border-radius: $radius-default;
      font-size: $type-teeny;
      display: inline-flex;
      display: block;
      margin: 0 0 3px 0;
    }

    strong {
      font-size: $type-tiny;
      display: block;
      color: $color-white-half;
      font-weight: $regular;
      margin: 0 0 3px 0;
    }

    small {
      padding: 0;
      display: block;
      margin: 0;
      font-size: $type-tiny;
      font-weight: $regular;
      margin: 0 0 3px 0;
    }

    &-queued {
      display: inline-flex;
      font-weight: $regular;
      color: $color-brand;
      font-size: $type-tiny;
      margin: 12px 0 3px 0;
    }

    &-latest {
      display: inline-flex;
      font-weight: $regular;
      color: $color-gray;
      font-size: $type-tiny;
      margin: 12px 0 3px 0;
    }

    &-counter {
      display: inline-flex;
      font-weight: $regular;
      font-size: $type-note;
      color: $color-white-medium;
      margin: 0;
      align-items: center;
      position: absolute;
      bottom: 12px;
      right: 12px;

      span {
        display: inline-flex;
        animation: fadeIn 1s ease-in-out;
      }

      strong {
        font-family: $font-mono;
        color: $color-white-default;
        font-size: $type-teeny;
        font-weight: $bold;
        padding: 0;
        margin: 0 3px;
      }
    }

    &-completed {
      display: inline-flex;
      font-weight: $regular;
      font-size: $type-tiny;
      margin: 0 0 3px 0;

      h1 {
        font-size: $type-tiny;
        padding: 0;
        margin: 0;
        text-transform: none;
        transition: none;
        font-weight: $regular;
        color: $color-brand;
      }

      p {
        color: $color-white-default;
        font-size: $type-copy;
        padding: 3px 0;
        margin: 0;
        text-transform: none;
      }

      strong {
        color: $color-white-medium;
        font-size: $type-default;
      }
    }
  }
}

.activity-status {
  &-container {
    @extend .scanner-status-container;
    padding: 24px;
    margin: 0;
    font-size: $type-copy;
    font-weight: $default;
    color: $color-white-default;
    text-transform: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: $color-white-element;
    
    @media (min-width: $break-md) {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr 300px 1fr;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &-rollup {
    display: flex;
    flex-direction: row;
    @media (min-width: $break-md) {
      flex-direction: column;
    }
  }

  &-item {
    display: flex;
    margin: 12px 0 0 0;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;

    &-text {
      color: $color-white-medium;
      font-size: $type-teeny;
      margin: 0;
      font-weight: $default;
      text-align: right;

      strong {
        font-weight: $default;
        font-size: $type-xxs;
        color: $color-white;
      }
    }
    &-count {
      font-weight: $default;
      color: $color-brand-default;
      font-size: $type-md;
      padding: 6px;
      border-radius: $radius-slight;
      margin: 0 0 0 12px;
      display: flex;
      min-width: 96px;
      background: $color-gray-light;
      justify-content: center;
    }
  }

  &-feed {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;

    &-empty {
      background: $color-gray-slight;
      border-radius: $radius-default;
      padding: 24px;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      min-height: 156px;
      position: relative;
      margin: 0 0 12px 0;
      
      @media (min-width: $break-md) {
        margin: 0;
        // @media (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2),
        // (min-width: 1280px) and (min-resolution: 192dpi) {
        background: repeating-linear-gradient(
          0deg,
          $color-gray-light,
          $color-gray-light 1.5px,
          transparent 1.5px,
          transparent 3px
        );
        box-shadow: 0 0 36px inset $color-gray-light;
      }
    }

    &-standard {
      @extend .activity-status-feed-empty;
      background: $color-white-element;
      box-shadow: none;

    }

    .Typewriter__wrapper {
      // TYPEWRITER

      a {
        display: flex;
        font-size: $type-default;
        color: $color-brand-medium;
        font-weight: $default;
        padding: 0;
        margin: 3px 0 0 0;
        text-decoration: none;
        cursor: pointer;
        align-items: center;
        transition: $transition-color;

        i {
          margin: 0 3px 0 0;
          align-self: flex-end;
        }

        &:hover {
          color: $color-brand;
          transition: $transition-color;
        }
      }

      h1 {
        margin: 0;
        padding: 0 0 6px 0;
        color: $color-white-medium;
        border-radius: $radius-slight;
        display: flex;
        font-size: $type-teeny;
      }

      h2 {
        font-size: $type-copy;
        color: $color-white-default;
        font-weight: $default;
        padding: 0;
        margin: 0;
      }
    }
  }
}
@use '../shared' as *;
@use '../components/general.scss' as *;



.category-select-wrapper {
  position: relative;
  display: inline-flex;
  margin: 0 0 12px 0;
  width: 100%;

  .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:hover,
  .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root,
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
  


  .MuiSelect-select,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $color-white;
    padding: 12px;
    border-radius: $radius-slight;
    color: $color-white;
    position: relative;
    outline: none;
    cursor: pointer;
    text-align: left;
    text-align-last: left;
    align-items: center;
    display: flex;
    -webkit-tap-highlight-color: transparent;
    background: transparent;
    background-image: none;
    font-weight: $default;
    font-family: $font-default;
    font-size: $type-input;
    min-width: 240px;
    // -webkit-transform: translateZ(0);
    // transform: translateZ(0);
    // text-size-adjust: 100%;
    // -webkit-text-size-adjust: 100%;
    background-color: $color-gray-light;
    border: none;
    margin: 6px 0 3px 0;

  

    &:hover,
    &:focus,
    &:focus-visible,
    &.Mui-focused {
      border: none;
    }

    @media (min-width: $break-md) {
      line-height: $type-default;
      font-size: $type-default;
      padding: 6px 12px;

      &::-ms-expand {
        display: none;
      }
    }

  }

  svg {
    color: $color-brand;
    width: 24px;
    @media (min-width: $break-md) {
      width: 16px;
    }
  }
}

.capsule {
  padding: 6px 24px;
  display: inline-flex;
  border-radius: $radius-slight;
  font-size: $type-md;
  font-weight: $default;
  flex-direction: column;
  margin: 6px 12px 12px 0;
  text-decoration: none;
  color: $color-white-default;
  background: $color-white-slight;
  transform-origin: center;
  border: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;

  @media (min-width: $break-md) {
    font-size: $type-md;
  }

  small {
    font-weight: $regular;
    color: $color-white-medium;
    font-size: $type-default;
  }

  &:hover {
    background: $color-gray-medium;
    color: $color-white;
  }

  &-container {
    border-radius: $radius-default;
    box-shadow: none;
  }

  img {
    max-width: 12px;
    height: auto;
    min-height: auto;
    padding: 0;
    margin: 0 6px 0 0;
  }
}


@use '../shared' as *;
@use '../components/general.scss' as *;

.activity {
  &-container {
    margin: 0;
    font-size: $type-copy;
    font-weight: $default;
    color: $color-white-default;
    text-transform: none;
    display: flex;
    flex-direction: column;
    padding: 36px 0;
    background: transparent;

    @media (min-width: $break-md) {
      padding: 0;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr 400px;
      flex-direction: column;
      justify-content: flex-start;
    }

    @media (min-width: $break-lg) {
      padding: 0;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr 500px;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &-item {
    display: flex;
    margin: 6px 0;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;

    &-text {
      color: $color-white-medium;
      font-size: $type-teeny;
      margin: 0;
      font-weight: $default;
      text-align: right;

      strong {
        font-weight: $default;
        font-size: $type-xxs;
        color: $color-white;
      }
    }
    &-count {
      font-weight: $default;
      color: $color-brand-default;
      font-size: $type-lg;
      padding: 3px 6px;
      border-radius: $radius-slight;
      margin: 0 0 0 12px;
      line-height: inherit;
      display: flex;
      min-width: 96px;
      background: $color-gray-light;
      justify-content: center;
    }
  }

  &-readout {
    background: $color-gray-light;
    border-radius: $radius-default;
    height: 100%;
    // width: 100%;
    min-height: 156px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: content-box;
    padding: 24px;
    margin: 12px;

    h1 {
      font-size: $type-default;
      font-weight: $default;
      padding: 0;
      color: $color-white;
      margin: 0 0 12px 0;
      text-transform: uppercase;
    }

    h2 {
      font-size: $type-lg;
      font-weight: $default;
      padding: 0;
      color: $color-brand;
      margin: 6px 0;
      text-transform: uppercase;
    }

    .Typewriter__cursor {
      color: $color-brand-medium;
    }

    @media (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1280px) and (min-resolution: 192dpi) {
      background: repeating-linear-gradient(
        0deg,
        $color-gray-half,
        $color-gray-half 1.5px,
        transparent 1.5px,
        transparent 3px
      );
      box-shadow: 0 0 36px inset $color-gray-half;
    }

    &-top {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;


      a {
        display: flex;
        font-size: $type-tiny;
        color: $color-gray;
        font-weight: $regular;
        padding: 0;
        margin: 3px 0 0 0;
        text-decoration: none;
        cursor: pointer;
        align-items: center;

        i {
          margin: 0 3px 0 0;
          align-self: flex-end;
        }

        &:hover {
          color: $color-gray;
        }
      }

      h1 {
        margin: 0;
        padding: 0 0 3px 0;
        font-size: $type-default;
        margin: 0;
        font-weight: $default;
        color: $color-brand;
        text-transform: uppercase;
      }

      h2 {
        font-size: $type-h2;
        font-weight: $default;
        padding: 0;
        color: $color-white-default;
        margin: 0;


        @media (min-width: $break-md) {
          font-size: $type-md;
        }

        strong {
          margin: 0 3px 0 0;
          font-weight: $default;
          color: $color-brand;
          text-transform: uppercase;
        }
      }
    }

    &-bottom {
      @extend .activity-readout-top;
      align-items: flex-end;
      // position: absolute;
      // bottom: 36px;
      // right: 48px;

   

      span {
        margin: 0;
        padding: 0;
      }

       a {
        display: flex;
        font-size: $type-tiny;
        color: $color-gray;
        font-weight: $regular;
        padding: 0;
        margin: 3px 0 0 0;
        text-decoration: none;
        cursor: pointer;
        align-items: center;

        i {
          margin: 0 3px 0 0;
          align-self: flex-end;
        }

        &:hover {
          color: $color-gray;
        }
      }


      h1 {
        margin: 0 0 3px 0;
        padding: 0;
        font-size: $type-default;
        font-weight: $default;

        span {
          text-transform: uppercase;
          margin: 0;
          color: $color-brand-default;
        }

        strong {
          font-size: $type-default;
          font-weight: $default;
          margin: 0;
          margin: 0 3px 0 0;
          color: $color-white-default;
          display: inline-flex;
          justify-content: center;
        }
      }
    }

  }

  &-inner {
    @extend .activity-readout;
    background: $color-gray-slight;
    box-shadow: none;
  }

  &-feed {
    display: flex;
    margin: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;

    &-standard {
      background: $color-gray-slight;
      border-radius: $radius-default;
      // height: 100%;
      width: 100%;
      box-sizing: border-box;
      min-height: 144px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: hidden;

      background: $color-white-element;
      box-shadow: none;
      padding: 0;
      margin: 0 0 24px 0;
    }

    &-single {
      border-radius: $radius-default;
      width: 100%;
      box-sizing: border-box;
      min-height: 144px;
      // display: flex;
      // flex-direction: column;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: hidden;
      background: $color-white-element;
      box-shadow: none;
      padding: 24px;
      margin: 0 0 24px 0;
    }

    &-categories {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

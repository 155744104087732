@use '../shared' as *;

.title {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  margin: 0;

  i {
    font-size: 2em;
    margin-right: 0.5em;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.2);
    }
  }

  small {
    font-size: $type-tiny;
    font-weight: $default;
    text-transform: uppercase;
    display: flex;
    color: $color-white-default;
    margin: 0;
    margin-top: 3px;
    line-height: auto;
  }

  img {
    height: 36px;
    margin: 0 12px 0 0;
    transition: $transition-transform;
    border-radius: $radius-default;
  }

  #effect {
    font-size: $type-icon;
    padding: 0;
    margin: 0;
    font-weight: $default;
    text-transform: none;
    transition: $transition-transform;

    small {
      font-size: $type-teeny;
    }

    span {
      color: $color-gray;
      text-transform: uppercase;
    }
  }

  &-sm {
    @extend .title;

    img {
      height: 24px;
      align-self: center;
      margin: 0 12px 0 0;
      transition: $transition-transform;
      border-radius: $radius-default;
    }

    #effect {
      @extend #effect;
      font-size: $type-md;
      

      small {
        font-size: $type-note;
      }
    }
  }
  &-headline {
    @extend .title;
    margin:  0;
    padding: 0;

    img {
      min-height: 24px;
      padding: 6px;
      margin:  0 12px 0 0;
      background: $color-gray-medium;
      border-radius: $radius-round;
    }

    

    #effect {
      @extend #effect;
      font-size: $type-md;
      

      small {
        font-size: $type-note;
      }
    }
  }
}

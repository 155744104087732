@use '../shared' as *;

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 84px;
  background: $color-nav;
  box-shadow: 0 0 24px $color-white-hint;
  color: $color-white;
  z-index: $z-nav;
  line-height: $type-sm;

  &-ticker-container {
    background: $color-gray-darker;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 84px;
    z-index: $z-ultimate !important;
    border-top: 1px solid $color-white-slight;
 
  }

  &-left {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: $type-md;

    .Typewriter__cursor {
      color: $color-brand;
    }
    span {
      font-weight: $default;

      strong {
        font-weight: $regular;
      }
    }

    small {
      font-size: $type-tiny;
      color: $color-brand;
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $color-white;
    text-transform: uppercase;
    text-align: right;

    .Typewriter__cursor {
      color: $color-white;
    }

    span {
      font-weight: $default;
      font-size: $type-tiny;
      color: $color-white;
    }

    a {
      font-weight: $regular;
      font-size: $type-tiny;
      color: $color-brand;
      text-decoration: underline;
    }

    small {
      font-size: $type-tiny;
      color: $color-brand;
      float: right;
    }

    button {
      background: transparent;
      font-size: $type-xxs;
      outline: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      font-family: $font-default;
      font-weight: $bold;
      padding: 6px;
      color: $color-brand;
      background: $color-brand-note;
      border: 1px solid $color-brand-light;
      border-radius: $radius-slight;
      margin: 0 0 0 12px;

      &:hover {
        background: $color-brand-slight;
      }
    }

    .module-icon {
      background: transparent;
      font-size: $type-md;
      outline: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      font-family: $font-default;
      font-weight: $bold;
      padding: 6px;
      color: $color-white;
      background: $color-white-note;
      border: none;
      border-radius: $radius-slight;
      margin: 0 0 0 24px;

      &:hover {
        background: $color-brand-slight;
      }
    }
  }
}

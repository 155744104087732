@use '../shared' as *;

.list {
  &-module {
    background: $color-white-element;

    &-scroll-indicator {
      font-size: 9px;
      position: sticky;
      top: 0;
      display: flex;
      padding: 6px 12px;
      background: linear-gradient(to right, transparent 72%, $color-gray-light 100%);
      z-index: 1000;
      color: $color-white-default;
      justify-content: flex-end;
      pointer-events: none;
      
      &-bottom {
        @extend .list-module-scroll-indicator;
        bottom: 0;
      }
    }
   
  
    &-border {
      background: linear-gradient(to right, transparent 0%, $color-white-slight 36%);
      height: 1px;
      width: 100%;
      display: block;
    }

    // ADD THIS IF YOU WANT TO SCROLL LIST
    height: 240px;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    position: relative;

    li {
      list-style: none;
      margin: 0;
      padding: 12px 24px;
      flex: 1;
      display: grid;
      grid-gap: 12px;
      grid-template-columns: 6px 36px 84px auto auto 100px;
      flex-direction: column;
      // border-bottom: 1px solid $color-white-note;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: linear-gradient(to right, transparent 0%, $color-white-element 36%);
        img {
          opacity: 1;
        }
      }

      &.selected {
        img {
          transition: $transition-all;
          transform: scale(1.2);
          opacity: 1;
        }
      }
      
      img {
        transition: $transition-all;
        opacity: .6;
        max-height: 24px;
        margin: 0 6px 0 0;
        border-radius: $radius-default;
      }

      &.logo {
        grid-template-columns: 6px 36px 84px auto auto 100px;
        // &:hover {
        //   background: $color-white-element;
        // }
      }

      &:not(.logo) {
        grid-template-columns: 6px 36px 84px auto auto 100px;
      }

      i {
        font-size: $type-default;
        color: $color-brand;
      }

      svg {
        height: 18px !important; // this adjusts default height of micro spark
      }

      &:last-child {
        border-bottom: 1px solid $color-white-slight;
      }

      h4 {
        font-size: $type-h4;
        padding: 0;
        margin: 0;
        font-weight: $default;
        max-width: 108px;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: $color-white-half;
        }
      }

      small {
        font-size: $type-note;
        text-transform: uppercase;
        display: flex;
        color: $color-white-default;
        margin: 2px 0 0 0;
      }
    }

    //USE THIS TO FADE OUT BOTTOM OF LIST IF NEEDED
    // &-bottom {
    //   position: sticky;
    //   height: 48px;
    //   bottom: 0;
    //   background: linear-gradient(
    //     to bottom,
    //     transparent 0%,
    //     $color-gray-medium 100%
    //   );
    // }
  }
}
